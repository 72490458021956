// VIDEOLOOP

// this function is also run by ReelDeck.vanilla.js and needs to be global scope
// use var here because storybook will dedeclare the variable and give an error
var videoloop = () => {
  const tryPlayVideo = async (src) => {
    const videoElement = document.createElement('video');
    videoElement.src = src;
    videoElement.autoplay = true;
    videoElement.setAttribute('muted', 'true'); // iOS only
    if (!videoElement.muted) {
      videoElement.muted = true;
    }
    videoElement.setAttribute('playsinline', 'true');
    try {
      await videoElement.play();
      videoElement.remove();
      return true;
    } catch (err) {
      return false;
    }
  };

  const loadPoster = (element) => {
    element.classList.add('tc_video__videoloop--show-poster');
  };

  (async () => {
    // Do we have some lazyplay elements?
    const videoloopsWithLazyPlay = document.querySelectorAll(
      '.tc_video__videoloop--lazyplay:not(.js-processed)',
    );
    if (!videoloopsWithLazyPlay.length) {
      return;
    }

    videoloopsWithLazyPlay.forEach((video) => {
      video.classList.add('js-processed');
    });

    // Is autoplay disabled in browser?
    const firstSrc = [].slice
      .apply(videoloopsWithLazyPlay)
      .find((elem) => !!elem.src).src;
    const canPlayVideo = await tryPlayVideo(firstSrc);
    if (!canPlayVideo) {
      videoloopsWithLazyPlay.forEach(loadPoster);
      return;
    }

    // When the the user has prefers-reduced-motion enabled
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (mediaQuery.matches) {
      videoloopsWithLazyPlay.forEach(loadPoster);
      return;
    }

    // IntersectionObserver NOT Supported
    if ((!'IntersectionObserver') in window) {
      videoloopsWithLazyPlay.forEach((video) => video.play());
      return;
    }

    // IntersectionObserver Supported
    const options = {
      rootMargin: '0px 0px 500px 0px',
    };

    const observer = new IntersectionObserver(observerEntries, options);
    videoloopsWithLazyPlay.forEach((video) => observer.observe(video));

    function observerEntries(entries) {
      entries.forEach((entry) => {
        const { target } = entry;

        if (entry.isIntersecting) {
          target.autoplay = true;
          target.play();
          return;
        }
        if (target.autoplay) {
          target.pause();
        }
      });
    }
  })();
};

videoloop();
